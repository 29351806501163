import React, { useEffect, useState, useRef } from "react";
import AppLayout from "../../components/AppLayout/index";
import Highlighter from "react-highlight-words";
import { Table, Tag, Input, Button, Space } from "antd";
import adidasData from "../../resources/adidas.json";
import nikeData from "../../resources/nike.json";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function Home() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [filteredData, setFilteredData] = useState([]);
  const { t, i18n } = useTranslation("common");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={t("shoesComparator.search")}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t("shoesComparator.search")}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {t("shoesComparator.reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: t("shoesComparator.name"),
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      render: (text, item) => (
        <Link
          to={`/shoesDetail/${item.brand.toLowerCase()}/${item.name
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/}/g, "")}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: t("shoesComparator.brand"),
      dataIndex: "brand",
      key: "brand",
      render: (text) => <p>{text}</p>,
      filters: [
        {
          text: "Adidas",
          value: "Adidas",
        },
        {
          text: "Nike",
          value: "Nike",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.brand === value,
    },
    // {
    //   title: "Year",
    //   dataIndex: "year",
    //   key: "year",
    //   render: (text) => <p>{text}</p>,
    // },
    {
      title: t("shoesComparator.whereToBuy"),
      dataIndex: "links",
      key: "links",
      render: (links, _) => (
        <div style={{ maxWidth: "200px" }}>
          {links.length > 0 ? (
            links.map((item, index) => (
              <a href={item.link} target="_blank">
                <Tag
                  color="#fbc02d"
                  key={index}
                  style={{ margin: 4, color: "#333333" }}
                >
                  {item.name}
                </Tag>
              </a>
            ))
          ) : (
            <p>None</p>
          )}
        </div>
      ),
    },
    {
      title: t("shoesComparator.gender"),
      dataIndex: "gender",
      key: "gender",
      filters: [
        {
          text: t("shoesComparator.man"),
          value: "Hombre",
        },
        {
          text: t("shoesComparator.woman"),
          value: "Mujer",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.gender.includes(value),
      render: (genders, _) => (
        <div>
          {genders.length > 0 ? (
            genders.map((item, index) => (
              <Tag color="#6c757d" key={index} style={{ margin: 4 }}>
                {item}
              </Tag>
            ))
          ) : (
            <p>None</p>
          )}
        </div>
      ),
    },
    {
      title: t("shoesComparator.distance"),
      dataIndex: "distance",
      key: "distance",
      filters: [
        {
          text: t("shoesComparator.shortDistance"),
          value: "Distancias cortas",
        },
        {
          text: t("shoesComparator.tenKm"),
          value: "10K",
        },
        {
          text: t("shoesComparator.halfMarathon"),
          value: "Media maraton",
        },
        {
          text: t("shoesComparator.marathon"),
          value: "Maraton",
        },
        {
          text: t("shoesComparator.ultraTrail"),
          value: "ultra-trail",
        },
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.distance.includes(value),
      render: (distances, _) => (
        <div style={{ maxWidth: "200px" }}>
          {distances.length > 0 ? (
            distances.map((item, index) => (
              <Tag color="#4caf50" key={index} style={{ margin: 4 }}>
                {item}
              </Tag>
            ))
          ) : (
            <p>None</p>
          )}
        </div>
      ),
    },
    {
      title: t("shoesComparator.pace"),
      dataIndex: "pace",
      key: "pace",
      filters: [
        {
          text: t("shoesComparator.paceLow"),
          value: "Bajo",
        },
        {
          text: t("shoesComparator.paceMedium"),
          value: "Medio",
        },
        {
          text: t("shoesComparator.paceHigh"),
          value: "Alto",
        },
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.pace.includes(value),
      render: (paces, _) => (
        <div style={{ maxWidth: "200px" }}>
          {paces.length > 0 ? (
            paces.map((item, index) => (
              <Tag color="#ff5722" key={index} style={{ margin: 4 }}>
                {item}
              </Tag>
            ))
          ) : (
            <p>None</p>
          )}
        </div>
      ),
    },
    {
      title: t("shoesComparator.surface"),
      dataIndex: "surface",
      key: "surface",
      filters: [
        {
          text: t("shoesComparator.surfaceAsphalt"),
          value: "Asfalto",
        },
        {
          text: t("shoesComparator.surfaceTrail"),
          value: "Trail",
        },
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.surface.includes(value),
      render: (surfaces, _) => (
        <div style={{ maxWidth: "200px" }}>
          {surfaces.length > 0 ? (
            surfaces.map((item, index) => (
              <Tag color="#8d6e63" key={index} style={{ margin: 4 }}>
                {item}
              </Tag>
            ))
          ) : (
            <p>None</p>
          )}
        </div>
      ),
    },
    // {
    //   title: "Use",
    //   dataIndex: "use",
    //   key: "use",
    //   render: (uses, _) => (
    //     <div style={{ maxWidth: "200px" }}>
    //       {uses.length > 0 ? (
    //         uses.map((item, index) => (
    //           <Tag
    //             color="#fbc02d"
    //             key={index}
    //             style={{ margin: 4, color: "#333333" }}
    //           >
    //             {item}
    //           </Tag>
    //         ))
    //       ) : (
    //         <p>None</p>
    //       )}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Cushioning",
    //   dataIndex: "cushioning",
    //   key: "cushioning",
    //   render: (cushioning, _) => (
    //     <div style={{ maxWidth: "200px" }}>
    //       {cushioning.length > 0 ? (
    //         cushioning.map((item, index) => (
    //           <Tag color="#ec407a" key={index} style={{ margin: 4 }}>
    //             {item}
    //           </Tag>
    //         ))
    //       ) : (
    //         <p>None</p>
    //       )}
    //     </div>
    //   ),
    // },
    {
      title: t("shoesComparator.pronation"),
      dataIndex: "pronation",
      key: "pronation",
      filters: [
        {
          text: t("shoesComparator.pronationNeutral"),
          value: "Neutro",
        },
        {
          text: t("shoesComparator.pronationSupinator"),
          value: "Supinador",
        },
        {
          text: t("shoesComparator.pronationPronator"),
          value: "Pronador",
        },
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.pronation.includes(value),
      render: (pronation, _) => (
        <div style={{ maxWidth: "200px" }}>
          {pronation.length > 0 ? (
            pronation.map((item, index) => (
              <Tag color="#2196f3" key={index} style={{ margin: 4 }}>
                {item}
              </Tag>
            ))
          ) : (
            <p>None</p>
          )}
        </div>
      ),
    },
    // {
    //   title: t("shoesComparator.footType"),
    //   dataIndex: "footType",
    //   key: "footType",
    //   render: (footTypes, _) => (
    //     <div style={{ maxWidth: "200px" }}>
    //       {footTypes.length > 0 ? (
    //         footTypes.map((item, index) => (
    //           <Tag color="#607d8b" key={index} style={{ margin: 4 }}>
    //             {item}
    //           </Tag>
    //         ))
    //       ) : (
    //         <p>None</p>
    //       )}
    //     </div>
    //   ),
    // },
  ];

  useEffect(() => {
    setup();
  }, []);

  async function setup() {
    const data = adidasData.concat(nikeData);
    data.sort(function (a, b) {
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0; // Names are equal
    });
    setFilteredData(data.filter((item) => item.links.length > 0));
  }

  const component = (
    <div>
      <div>
        <h1>{t("shoesComparator.title")}</h1>
        <p>{t("shoesComparator.subtitle")}</p>
      </div>
      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey={(record) => record.name}
        scroll={{ x: 1400 }}
      />
    </div>
  );

  return <AppLayout childComponent={component} defaultKey="1" />;
}
