import React from "react";
import { Route } from "react-router-dom";
import ShoesDetail from "./views/ShoesDetail/index";

const Sitemap = ({ data }) => {
	const generateXmlUrl = (path) => {
		return `
      <url>
        <loc>https://runnerschoice.run${path}</loc>
      </url>
    `;
	};

	const generateRoutes = (data) => {
		return data.map((item, index) => (
			<Route
				key={index}
				path={`#/shoesDetail/${item.brand.toLowerCase()}/${item.name
					.toLowerCase()
					.replace(/\s+/g, "-")
					.replace(/}/g, "")}`}
			/>
		));
	};

	const generateXmlSitemap = () => {
		const staticUrls = [
			"/", // Home
			"/sitemap", // Sitemap
			// Add other static routes as needed
		];

		const dynamicUrls = data
			? generateRoutes(data).map((route) => generateXmlUrl(route.props.path))
			: [];

		return `
      <?xml version="1.0" encoding="UTF-8"?>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        ${[...staticUrls, ...dynamicUrls].join("")}
      </urlset>
    `;
	};

	return <pre>{generateXmlSitemap()}</pre>;
};

export default Sitemap;
