import React, { useEffect, useState, useRef } from "react";
import AppLayout from "../../components/AppLayout/index";
import Highlighter from "react-highlight-words";
import { Table, Tag, Input, Button, Space } from "antd";
import racesData from "../../resources/races/races.json";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function Races() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [filteredData, setFilteredData] = useState([]);
  const { t, i18n } = useTranslation("common");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={t("shoesComparator.search")}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t("shoesComparator.search")}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {t("shoesComparator.reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const uniqueCountries = Array.from(
    new Set(filteredData.flatMap((record) => record.country))
  );

  const columns = [
    {
      title: t("races.name"),
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      render: (text, item) => (
        <Link
          to={`/raceDetail/${item.name
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/}/g, "")}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: t('races.registration'),
      dataIndex: 'registration_url',
      key: 'registration_url',
      render: (registration, item) => (
        <div style={{ maxWidth: "200px" }}>
          {item.registration_url ?               
          <a href={item.registration_url} target="_blank">
                <Tag
                  color="#fbc02d"
                  style={{ margin: 4, color: "#333333" }}
                >
                  {t('races.registration')}
                </Tag>
          </a> : <p>None</p>}
        </div>
      ),
    },
    {
      title: t('races.date'),
      dataIndex: 'date',
      key: 'date',
      filters: [
        {
          text: t("races.january"),
          value: "Jan",
        },
        {
          text: t("races.february"),
          value: "Feb",
        },
        {
          text: t("races.march"),
          value: "Mar",
        },
        {
          text: t("races.april"),
          value: "Apr",
        },
        {
          text: t("races.may"),
          value: "May",
        },
        {
          text: t("races.june"),
          value: "Jun",
        },
        {
          text: t("races.july"),
          value: "Jul",
        },
        {
          text: t("races.august"),
          value: "Aug",
        },
        {
          text: t("races.september"),
          value: "Sep",
        },
        {
          text: t("races.october"),
          value: "Oct",
        },
        {
          text: t("races.november"),
          value: "Nov",
        },
        {
          text: t("races.december"),
          value: "Dec",
        },
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.date.includes(value),
      render: (date, item) => (
        <p>{item.date}</p>
      ),
    },
    {
      title: t("races.distances"),
      dataIndex: "distances",
      key: "distances",
      filters: [
        {
          text: "5 Km",
          value: "5 km",
        },
        {
          text: "10 Km",
          value: "10 km",
        },
        {
          text: "20 Km",
          value: "20 km",
        },
        {
          text: "21 Km",
          value: "21.097 km",
        },
        {
          text: "42 Km",
          value: "42.195 km",
        }
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.distances.includes(value),
      render: (distances, _) => (
        <div style={{ maxWidth: "200px" }}>
          {distances.length > 0 ? (
            distances.map((item, index) => (
              <Tag color="#4caf50" key={index} style={{ margin: 4 }}>
                {item}
              </Tag>
            ))
          ) : (
            <p>None</p>
          )}
        </div>
      ),
    },
    {
      title: t('races.country'),
      dataIndex: 'country',
      key: 'country',
      filters: uniqueCountries.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' })).map((country) => ({
        text: country,
        value: country,
      })),
      filterMultiple: true,
      onFilter: (value, record) => record.country.includes(value),
      render: (country, item) => (
        <p>{item.country}</p>
      ),
    },
    {
      title: t('races.location'),
      dataIndex: 'location',
      key: 'location',
      render: (location, item) => (
        <p>{item.location}</p>
      ),
    }
  ];

  useEffect(() => {
    setup();
  }, []);

  async function setup() {
    var data = racesData;
    var currentDate = new Date();
    data = data.sort(sortByDate);
    var futureDatesData = data.filter(function (item) {
       var eventDate = new Date(item.date);
       return eventDate > currentDate;
    });
    setFilteredData(futureDatesData.filter(function (item) {
      return item.name !== null;
  }));
  }

  function sortByDate(a, b) {
    var dateA = new Date(a.date);
    var dateB = new Date(b.date);

    return dateA - dateB;
}

  const component = (
    <div>
      <div>
        <h1>{t("races.title")}</h1>
        <p>{t("races.subtitle")}</p>
      </div>
      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey={(record) => record.name}
        scroll={{ x: 1400 }}
      />
    </div>
  );

  return <AppLayout childComponent={component} defaultKey="2" />;
}
