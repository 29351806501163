import React from "react";
import { Layout, Menu, theme } from "antd";
import { HomeOutlined, UnorderedListOutlined } from "@ant-design/icons";
import * as ROUTES from "../../utils/routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Header, Content, Footer } = Layout;

const AppLayout = (props) => {
  const { childComponent } = props;

  const { t, i18n } = useTranslation("common");
  let navigate = useNavigate();

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout className="layout" style={{ minHeight: "100vh" }}>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          style={{ minWidth: 0, flex: "auto" }}
        >
          <Menu.Item
            key="1"
            icon={<HomeOutlined />}
            onClick={() => navigate(ROUTES.HOME)}
          >
            {t("navBar.home")}
          </Menu.Item>
          <Menu.Item
            key="2"
            icon={<UnorderedListOutlined />}
            onClick={() => navigate(ROUTES.RACES)}
          >
            {t("navBar.races")}
          </Menu.Item>
        </Menu>
      </Header>
      <Content
        style={{
          padding: "50px 50px",
        }}
      >
        <div className="site-layout-content">{childComponent}</div>
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        <div>RunnersChoice ©2023</div>
        <div style={{ marginTop: 8 }}> {t("navBar.disclaimer")}</div>
      </Footer>
    </Layout>
  );
};
export default AppLayout;
