import React, { useEffect, useState } from "react";
import AppLayout from "../../components/AppLayout/index";
import { Card, Col, Tag, Row } from "antd";
import racesData from "../../resources/races/races.json";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function RaceDetail(props) {
	const { name } = useParams();
	const { t, i18n } = useTranslation("common");

	const [raceName, setRaceName] = useState("");
	const [raceItem, setRaceItem] = useState(null);

	useEffect(() => {
		setup();
	}, []);

	function setup() {
		findRace();
		setRaceName(getName());
	}

	function getName() {
		return name.replace(/-/g, " ");
	}

	function findRaceByName(name) {
		return racesData.find(
			(race) => race.name.toLowerCase() === name.toLowerCase()
		);
	}

	function findRace() {
		const raceData = findRaceByName(getName());
		setRaceItem(raceData);
	}

	const component = (
		<div>
			<div>
				<h1>
					{t("raceDetail.title")} {raceName}
				</h1>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginTop: 18,
				}}
			>
				<Card title={raceName} bordered={true} style={{ width: "100%" }}>
					<Row
						gutter={{
							xs: 8,
							sm: 16,
							md: 24,
							lg: 32,
						}}
					>
						<Col span={24}>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<p style={{ fontWeight: "bold" }}>
									{t("raceDetail.date")}&nbsp;
								</p>
								<p>{raceItem?.date}</p>
							</div>
						</Col>
						<Col span={24}>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<p style={{ fontWeight: "bold" }}>
									{t("raceDetail.location")}&nbsp;
								</p>
								<p>{raceItem?.location}</p>
							</div>
						</Col>
						<Col span={24}>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<p style={{ fontWeight: "bold" }}>
									{t("raceDetail.distance")}&nbsp;
								</p>
								<div style={{ maxWidth: "200px" }}>
									{raceItem?.distances.length > 0 ? (
										raceItem?.distances.map((item, index) => (
											<Tag color="#4caf50" key={index} style={{ margin: 4 }}>
												{item}
											</Tag>
										))
									) : (
										<p>None</p>
									)}
								</div>
							</div>
						</Col>
						<Col span={24}>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<p style={{ fontWeight: "bold" }}>
									{t("raceDetail.registration")}&nbsp;
								</p>
								<div>
									{raceItem?.registration_url ? (
										<a href={raceItem?.registration_url} target="_blank">
											<Tag
												color="#fbc02d"
												style={{ margin: 4, color: "#333333" }}
											>
												{t("races.registration")}
											</Tag>
										</a>
									) : (
										<p>None</p>
									)}
								</div>
							</div>
						</Col>
					</Row>
				</Card>
			</div>
		</div>
	);

	return <AppLayout childComponent={component} defaultKey="2" />;
}
