import "./App.css";
import { ConfigProvider } from "antd";
import { HashRouter, Route, Routes } from "react-router-dom";
import * as ROUTES from "./utils/routes";
import Home from "./views/Home/index";
import Races from "./views/Races/index"
import ShoesDetail from "./views/ShoesDetail/index";
import RaceDetail from "./views/RaceDetail";
import Sitemap from "./SiteMap";
import React from "react";
import adidasData from "./resources/adidas.json";
import nikeData from "./resources/nike.json";

const data = adidasData.concat(nikeData);

const generateRoutes = () => {
  return data.map((item, index) => (
    <Route
      key={index}
      path={`/shoesDetail/${item.brand.toLowerCase()}/${item.name
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/}/g, "")}`}
      element={<ShoesDetail />}
    />
  ));
};

function App() {
  return (
    <ConfigProvider theme={{ token: { colorPrimary: "#00b96b" } }}>
      <HashRouter>
        <Routes>
          <Route exact path={ROUTES.HOME} element={<Home />} />
          <Route exact path={ROUTES.RACES} element={<Races />} />
          <Route path={ROUTES.DETAIL_SHOES} element={<ShoesDetail />} />
          <Route path={ROUTES.DETAIL_RACE} element={<RaceDetail />} />
          {/* {generateRoutes()} */}
          <Route path={ROUTES.SITEMAP} element={<Sitemap data={data} />} />
        </Routes>
      </HashRouter>
    </ConfigProvider>
  );
}

export default App;
