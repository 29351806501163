import React, { useEffect, useState } from "react";
import AppLayout from "../../components/AppLayout/index";
import { Card, Col, Tag, Row } from "antd";
import adidasData from "../../resources/adidas.json";
import nikeData from "../../resources/nike.json";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function ShoesDetail(props) {
  const { brand, name } = useParams();
  const { t, i18n } = useTranslation("common");

  const [shoesName, setShoesName] = useState("");
  const [shoesItem, setShoesItem] = useState(null);

  useEffect(() => {
    setup();
  }, []);

  function setup() {
    findShoes();
    setShoesName(getName());
  }

  function getName() {
    return name.replace(/-/g, " ");
  }

  function findShoeByName(data, name) {
    return data.find((shoe) => shoe.name.toLowerCase() === name.toLowerCase());
  }

  function findShoes() {
    var shoesData = null;
    switch (brand.toLowerCase()) {
      case "adidas":
        shoesData = findShoeByName(adidasData, getName());
        console.log(shoesData);
        setShoesItem(shoesData);
        return;
      case "nike":
        shoesData = findShoeByName(nikeData, getName());
        setShoesItem(shoesData);
        return;
      default:
        setShoesName(t("shoesDetail.errorShoesNotFound"));
        return;
    }
  }

  const component = (
    <div>
      <div>
        <h1>
          {t("shoesDetail.title")} {shoesName}
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 18,
        }}
      >
        <Card title={shoesName} bordered={true} style={{ width: "100%" }}>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold" }}>
                  {t("shoesDetail.brand")}&nbsp;
                </p>
                <p>{shoesItem?.brand}</p>
              </div>
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold" }}>
                  {t("shoesDetail.year")}&nbsp;
                </p>
                <p>{shoesItem?.year}</p>
              </div>
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold" }}>
                  {t("shoesDetail.gender")}&nbsp;
                </p>
                {shoesItem?.gender.length > 0 ? (
                  shoesItem?.gender.map((item, index) => (
                    <Tag color="#6c757d" key={index} style={{ margin: 4 }}>
                      {item}
                    </Tag>
                  ))
                ) : (
                  <p>None</p>
                )}
              </div>
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold" }}>
                  {t("shoesDetail.distance")}&nbsp;
                </p>
                {shoesItem?.distance.length > 0 ? (
                  shoesItem?.distance.map((item, index) => (
                    <Tag color="#4caf50" key={index} style={{ margin: 4 }}>
                      {item}
                    </Tag>
                  ))
                ) : (
                  <p>None</p>
                )}
              </div>
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold" }}>
                  {t("shoesDetail.pace")}&nbsp;
                </p>
                {shoesItem?.pace.length > 0 ? (
                  shoesItem?.pace.map((item, index) => (
                    <Tag color="#ff5722" key={index} style={{ margin: 4 }}>
                      {item}
                    </Tag>
                  ))
                ) : (
                  <p>None</p>
                )}
              </div>
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold" }}>
                  {t("shoesDetail.surface")}&nbsp;
                </p>
                {shoesItem?.surface.length > 0 ? (
                  shoesItem?.surface.map((item, index) => (
                    <Tag color="#8d6e63" key={index} style={{ margin: 4 }}>
                      {item}
                    </Tag>
                  ))
                ) : (
                  <p>None</p>
                )}
              </div>
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold" }}>
                  {t("shoesDetail.pronation")}&nbsp;
                </p>
                {shoesItem?.pronation.length > 0 ? (
                  shoesItem?.pronation.map((item, index) => (
                    <Tag color="#2196f3" key={index} style={{ margin: 4 }}>
                      {item}
                    </Tag>
                  ))
                ) : (
                  <p>None</p>
                )}
              </div>
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "bold" }}>
                  {t("shoesDetail.whereToBuy")}&nbsp;
                </p>
                {shoesItem?.links.length > 0 ? (
                  shoesItem?.links.map((item, index) => (
                    <a href={item.link} target="_blank">
                      <Tag
                        color="#fbc02d"
                        key={index}
                        style={{ margin: 4, color: "#333333" }}
                      >
                        {item.name}
                      </Tag>
                    </a>
                  ))
                ) : (
                  <p>None</p>
                )}
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );

  return <AppLayout childComponent={component} defaultKey="1" />;
}
